<template>
  <div class="breakdownPaper">
    <!-- 试卷名称 start -->
    <div class="title">
      <div class="title-left"></div>
      <div class="title-name">试卷名称</div>
      <el-input
        v-model="submitData.name"
        placeholder="请输入试卷名称"
      ></el-input>
    </div>
    <el-checkbox-group v-model="checkList">
      <el-checkbox :label="1">过滤图片题</el-checkbox>
      <el-checkbox :label="2">过滤无答案解析</el-checkbox>
    </el-checkbox-group>
    <!-- 试卷名称 end -->
    <!-- 试卷要求 start -->
    <!-- <div class="title">
      <div class="title-left"></div>
      <div class="title-name">试卷要求</div>
      <div class="title-msg">
        年级：
        <el-select v-model="submitData.resGradeId" placeholder="请选择">
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        省份：
        <el-select v-model="submitData.province" placeholder="请选择">
          <el-option
            v-for="item in provinceData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div> -->
    <div class="range-box">
      <el-checkbox v-model="isBook">限定教材章节范围</el-checkbox>
      <el-select
        v-if="isBook"
        v-model="submitData.editionId"
        placeholder="请选择教材"
        @change="setBookData"
      >
        <template v-for="item in editionlistData">
          <el-option :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </template>
      </el-select>
    </div>
    <div v-if="isBook" class="range-box">
      <el-checkbox-group v-model="submitData.bookIds" @change="getChapter">
        <el-checkbox v-for="(v, i) in bookListData" :key="i" :label="v.value">{{
          v.label
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <!-- 章节信息 -->
    <div v-if="isBook" class="chapte-box">
      <template v-for="(v, i) in bookListData">
        <!-- {{ submitData.bookIds.indexOf(v.value) != -1 }} -->
        <!-- {{ submitData.bookIds.indexOf(v.value) != -1 }}
        {{ submitData.bookIds }}
        {{ v.value }} -->
        <div
          v-if="submitData.bookIds.indexOf(v.value) != -1"
          :key="i"
          class="chapte-list"
        >
          <div class="chapte-left-box">{{ v.label }}：</div>
          <div class="chapte-right-box">
            <el-checkbox-group v-model="submitData.chapterIds">
              <!-- bookId -->
              <template v-for="(m, n) in getbookchapterData[v.value]">
                <el-checkbox :key="n" :label="m.id">{{ m.name }}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
      </template>
    </div>
    <!-- 试卷要求 end -->
    <!-- 试卷题量题型 start -->
    <div class="title">
      <div class="title-left"></div>
      <div class="title-name">试卷题量题型</div>
      <el-button type="text" icon="el-icon-delete" @click="delAll">
        一键删除
      </el-button>
    </div>
    <div class="question-quantity">
      <div v-for="(v, i) in questionTypeData" :key="i" class="quantity-list">
        {{ v.questionTypeName }}
        <el-input-number
          v-model="v.number"
          :min="0"
          :label="v.questionTypeName"
          @change="handleChange(v)"
        ></el-input-number>
      </div>
    </div>
    <!-- 底部提交 盒子 -->
    <div class="btm-box">
      <el-button
        class="btn-all"
        type="primary"
        :loading="btnLoading"
        @click="submit(1)"
        >保存细目表</el-button
      >
      <el-button :loading="btnLoading" @click="submit(0)">下一步</el-button>
    </div>
    <!-- 试卷题量题型 end -->
    <!-- 细目表明细 start -->
    <div class="title">
      <div class="title-left"></div>
      <div class="title-name">细目表明细</div>
      <!-- {{ submitData.chapterIds }} -->
      <el-button
        v-if="isBook"
        type="text"
        icon="el-icon-refresh"
        @click="setNewQuestionList"
      >
        根据章节过滤知识点
      </el-button>
    </div>
    <el-table :data="typeList" border style="width: 100%">
      <el-table-column prop="questionId" label="题号" width="80" align="center">
      </el-table-column>
      <el-table-column
        prop="questionTypeName"
        label="题型"
        width="80"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="knowledgeNames"
        label="知识点"
        align="center"
        width="530"
      >
        <template slot-scope="scope">
          <div class="know-box">
            <div
              v-for="(v, i) in scope.row.knowledgeNames"
              :key="i"
              class="know-list"
            >
              {{ v }}
              <i class="el-icon-close" @click="delKnow(scope.row, i)"></i>
            </div>
            <el-button
              v-if="scope.row.knowledgeNames.length != 5"
              type="primary"
              icon="el-icon-plus"
              class="btn-all"
              @click="addknow(scope.row)"
            >
              <template v-if="scope.row.knowledgeNames.length == 0"
                >添加知识点</template
              >
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="difficulty" label="难度" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.difficulty" placeholder="请选择难度">
            <el-option
              v-for="item in difficultyList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="分值" align="center">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.score" :min="0"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <i
            class="el-icon-delete list-del"
            @click="delTypeList(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <!-- 二叉树的弹出层 -->
    <el-dialog
      v-dialogDrag
      title="批量选择知识点"
      :visible.sync="twoTree"
      width="50%"
      :modal="false"
    >
      <div class="two-tree-box">
        <div>
          <el-tree
            v-if="twoTreetype"
            :key="newKeys"
            ref="tree"
            :data="knowledgeData"
            show-checkbox
            node-key="number"
            :default-checked-keys="loreTureArrid"
            :default-expanded-keys="twoTreeArray"
            :props="defaultProps"
            @check="elTreeChange"
          >
          </el-tree>
        </div>
        <div style="width: 300px">
          <el-input
            v-model="twoTreeVal"
            placeholder="请输入内容"
            class="input-with-select"
            :debounce="500"
          >
            <el-button
              slot="append"
              class="search-btn"
              icon="el-icon-search"
              @click="searchLore"
            ></el-button>
          </el-input>
          <el-checkbox-group
            v-model="loreTureArrid"
            style="width: 200px; margin-top: 20px"
          >
            <template v-for="(v, i) in TreeArray">
              <el-checkbox :key="i" :label="v.number">{{ v.name }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twoTree = false">取 消</el-button>
        <el-button type="primary" @click="isOkTree">确 定</el-button>
      </span>
    </el-dialog>
    <el-button class="my-link" type="danger" plain @click="toMysp"
      >我的细目表</el-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getbooklistbylevelandsubject } from "@/core/api/resource/knowledgePointsPaper";
import {
  getquestiontype,
  firstchapter,
  exampapercondition,
  getExampapercondition,
  putExampapercondition,
  // continueconditionexampaper
} from "@/core/api/resource/breakdownPaper";
import {
  difficultyList,
  gradeMiddleOptions,
  gradeHighOptions,
} from "@/core/util/constdata";
import { getProvinceList } from "@/core/api/cms/geo";
import { getQuestionPoint } from "@/core/api/exam/examLore";
export default {
  name: "BreakdownPaper",
  data() {
    return {
      input: "",
      value: "",
      options: [],
      checkList: [1, 2],
      isBook: false,
      btnLoading: false,
      num: 0,
      tableData: [],
      submitData: {
        name: "",
        // resGradeId: "",
        // province: "",
        editionId: "",
        bookIds: [],
        chapterIds: [],
      },
      editionlistData: [],
      provinceData: [],
      questionTypeData: [],
      bookListData: [],
      chapterDataList: [],
      getbookchapterData: [],
      oldbookIds: [],
      typeList: [],
      gradeMiddleOptions: gradeMiddleOptions,
      gradeHighOptions: gradeHighOptions,
      difficultyList: difficultyList,

      // 知识点二叉树
      twoTreeVal: "",
      loreTureArrid: "",
      TreeArray: [],
      knowledgeData: [],
      twoTreeArray: [],
      twoTree: false,
      twoTreetype: true,
      newKeys: 1,
      defaultProps: {
        children: "child",
        label: "name",
      },
      pageId: 0,
      chapterIdsType: true,

      // submitData.chapterIds
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    "submitData.bookIds": {
      deep: true,
      handler() {
        // this.getToList(true);
        // console.log(this.getbookchapterData);
      },
    },
    isBook() {
      if (this.isBook == false) {
        this.submitData.chapterIds = [];
        this.getToList(true);
      }
    },
    twoTreeVal() {
      this.searchLore();
    },
    "submitData.chapterIds": {
      deep: true,
      handler() {
        this.getToList(true);
        // this.chapterIdsType = false;

        // console.log(this.submitData.chapterIds);
      },
    },
  },
  created() {
    this.getOptions();
    this.getToList();
  },
  methods: {
    setNewQuestionList() {
      // console.log(this.loreArr);
      // console.log(this.knowledgeData);
      // console.log(this.typeList);
      this.typeList.forEach((index) => {
        // console.log(index);
        index.knowledgeIds.forEach((item, a) => {
          let type = this.loreArr.filter((i) => i.number == item);
          if (type.length == 0) {
            index.knowledgeIds.splice(a, 1);
            index.knowledgeNames.splice(a, 1);
          }
        });
      });
      this.typeList = [...this.typeList];
      this.$message({
        message: "过滤成功",
        type: "success",
      });
    },
    goLink() {
      this.$router.push({
        name: "QuestionList",
      });
    },
    // 初始化页面 查一下是否有id 有id就是编辑
    init() {
      //通过细目表列表进入的
      if (this.$route.query.id) {
        this.pageId = this.$route.query.id;
        this.getInitData();
      }
      // //通过试卷内
      // if (this.$route.query.paperId) {
      //   this.paperId = this.$route.query.paperId;
      //   this.getPaper();
      // }
    },
    //根据paperId获取planId
    // getPaper() {
    //   let data = {
    //     id: this.paperId
    //   };
    //   continueconditionexampaper(data).then(res => {
    //     // console.log(res);
    //     this.pageId = res.data.data;
    //     this.getInitData();
    //   });
    // },
    //根据planId获取内容
    getInitData() {
      getExampapercondition(this.pageId).then((res) => {
        this.submitData = res.data.data;
        // isImg hasAnswer
        this.checkList = [];
        if (this.submitData.isImg) {
          this.checkList.push(1);
        }
        if (this.submitData.hasAnswer) {
          this.checkList.push(2);
        }
        if (this.submitData.bookIds) {
          this.submitData.bookIds = this.submitData.bookIds.split(",");
        } else {
          this.submitData.bookIds = [];
        }
        if (this.submitData.chapterIds) {
          this.submitData.chapterIds = this.submitData.chapterIds.split(",");
        } else {
          this.submitData.chapterIds = [];
        }

        // this.submitData.chapterIds = this.submitData.chapterIds.split(",");
        this.submitData = Object.assign({}, this.submitData);
        if (this.submitData.bookIds[0] == "") {
          this.submitData.bookIds = [];
          this.submitData.chapterIds = [];
        }
        // console.log(this.submitData.bookIds);
        if (this.submitData.bookIds.length > 0) {
          this.isBook = true;
          // /处理数组 讲字符串转为number
          let chapterIds = [];
          this.submitData.chapterIds.map((index) => {
            chapterIds.push(parseInt(index));
          });
          this.submitData.chapterIds = chapterIds;
          let arr = [];
          this.submitData.bookIds.map((index) => {
            arr.push(parseInt(index));
          });
          this.submitData.bookIds = arr;
          setTimeout(() => {
            this.setBookData(this.submitData.editionId, 1);
            this.getChapter();
          }, 500);
        }
        // questionConditionList questionTypeSetting
        this.questionTypeData = JSON.parse(this.submitData.questionTypeSetting);
        this.typeList = JSON.parse(this.submitData.questionConditionList);
        if (this.typeList && this.typeList.length == 0) {
          return;
        }
        this.typeList.map((index) => {
          index.knowledgeNames = index.knowledgeNames.split("#");
          index.knowledgeIds = index.knowledgeIds.split("#");
        });
        this.typeList = JSON.parse(JSON.stringify(this.typeList));
        // console.log(this.typeList);
      });
    },
    toMysp() {
      this.$router.push({
        name: "SpecificList",
      });
    },
    submit(val) {
      if (!this.submitData.name) {
        this.$message({
          message: "请输入试卷名称",
          type: "warning",
        });
        return;
      }
      // if (!this.submitData.resGradeId) {
      //   this.$message({
      //     message: "请选择年级",
      //     type: "warning"
      //   });
      //   return;
      // }
      // if (!this.submitData.province) {
      //   this.$message({
      //     message: "请选择省份",
      //     type: "warning"
      //   });
      //   return;
      // }
      if (this.isBook) {
        if (!this.submitData.editionId) {
          this.$message({
            message: "请选择教材版本",
            type: "warning",
          });
          return;
        }
        if (this.submitData.bookIds.length == 0) {
          this.$message({
            message: "请选择教材",
            type: "warning",
          });
          return;
        }
        if (this.submitData.chapterIds.length == 0) {
          this.$message({
            message: "请选择章节",
            type: "warning",
          });
          return;
        }
      }
      if (this.typeList.length == 0) {
        this.$message({
          message: "至少添加一道题",
          type: "warning",
        });
        return;
      }
      let type = false;
      try {
        this.typeList.forEach((index) => {
          if (index.knowledgeIds.length == 0) {
            this.$message({
              message: `第${index.questionId}题知识点为空`,
              type: "warning",
            });
            type = true;
            throw Error();
          }
        });
      } catch (e) {
        // throw Error();
      }
      if (type) {
        return;
      }

      let data = Object.assign({}, this.submitData);
      // console.log(this.checkList);
      // console.log(this.checkList.indexOf(1) != -1);
      // console.log(this.checkList.indexOf(2) != -1);
      if (this.checkList.indexOf(1) != -1) {
        data.isImg = 1;
      } else {
        data.isImg = 0;
      }
      if (this.checkList.indexOf(2) != -1) {
        data.hasAnswer = 1;
      } else {
        data.hasAnswer = 0;
      }
      data.levelId = this.getLevelId;
      data.subjectId = this.getSubjectId;
      if (this.isBook) {
        data.bookIds = data.bookIds.join(",");
        data.chapterIds = data.chapterIds.join(",");
      } else {
        data.editionId = "";
        data.bookIds = "";
        data.chapterIds = "";
      }

      data.questionTypeSetting = JSON.stringify(this.questionTypeData);
      data.questionConditionList = JSON.parse(JSON.stringify(this.typeList));
      data.questionConditionList.map((index) => {
        index.knowledgeIds = index.knowledgeIds.join("#");
        index.knowledgeNames = index.knowledgeNames.join("#");
      });
      data.questionConditionList = JSON.stringify(data.questionConditionList);
      data.status = val.toString();
      // console.log(this.typeList);
      this.btnLoading = true;
      // 有pageId就是修改 val = 0的时候 下一步 不管是不是存在pageId 都新增

      if (this.pageId) {
        if (val == 0) {
          data.status = "0";
          delete data.id;
          this.postExampapercondition(data, 1);
        } else {
          this.putExampapercondition(data);
        }
      } else {
        if (val == 0) {
          // 第二个值主要是判断跳页面   如果是 2 就是保存进入细目表列表
          this.postExampapercondition(data, 1);
        } else {
          this.postExampapercondition(data, 2);
        }
      }
    },
    putExampapercondition(data) {
      putExampapercondition(data)
        .then(() => {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    postExampapercondition(data, val) {
      exampapercondition(data)
        .then((res) => {
          if (val == 1) {
            let routerData = JSON.stringify({
              id: res.data.data.id,
              name: this.submitData.name,
            });
            let Base64 = require("js-base64").Base64;
            // console.log(Base64.encode(routerData));
            this.$router.push({
              name: "QuestionList",
              query: { code: Base64.encode(routerData) },
            });
          } else {
            setTimeout(() => {
              this.$router.push({
                name: "SpecificList",
                query: { id: res.data.data.id },
              });
            }, 1000);
            this.$message({
              message: "保存成功",
              type: "success",
            });
          }
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    delAll() {
      this.$confirm("确认删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.typeList = [];
          this.questionTypeData.map((item) => {
            item.number = 0;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“删除”操作",
          });
        });
    },
    delKnow(list, i) {
      list.knowledgeNames.splice(i, 1);
      list.knowledgeIds.splice(i, 1);
    },
    addknow(val) {
      this.loreTureArrid = [];
      this.indexQuertion = Object.assign({}, val);
      this.loreTureArrid = this.indexQuertion.knowledgeIds;
      this.newKeys = new Date().getTime();
      //判断下 修改章节后 是否已经重新请求数据
      // if (this.chapterIdsType) {
      this.twoTree = true;
      // } else {
      //   this.getToList(true);
      // }
    },
    elTreeChange() {
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    //搜索知识点最后一级
    searchLore() {
      if (this.twoTreeVal == "") {
        this.TreeArray = [];
        return;
      }
      let arr = [];
      this.TreeArray = [];
      this.loreArr.forEach((i) => {
        if (i.name.indexOf(this.twoTreeVal) >= 0) {
          arr.push(i.id);
          if (i.child.length == 0) {
            this.TreeArray.push(i);
          }
        }
      });
      if (arr.length == 0) {
        this.$message({
          message: "没有找到相似知识点",
          type: "warning",
        });
        this.twoTreetype = true;
        return;
      }
      this.twoTreeArray = arr;
      this.newKeys = new Date().getTime();
    },
    isOkTree() {
      let loreTureArr = [];
      let arrTureArrid = this.$refs.tree.getCheckedKeys();
      // 去除存在子集的id
      this.loreArr.forEach((i) => {
        arrTureArrid.forEach((item, index) => {
          if (i.number == item && i.child.length > 0) {
            arrTureArrid.splice(index, 1);
          }
        });
      });
      // 判断是否超过5条
      if (arrTureArrid.length > 5) {
        this.$message({
          message: "最多保存5条知识点",
          type: "warning",
        });
        return;
      }
      this.loreArr.forEach((i) => {
        if (arrTureArrid.indexOf(i.number) != -1 && i.child.length == 0) {
          loreTureArr.push(i.name);
        }
      });
      // indexQuertion
      this.typeList.map((index) => {
        if (index.questionId == this.indexQuertion.questionId) {
          index.knowledgeIds = arrTureArrid;
          index.knowledgeNames = loreTureArr;
        }
      });
      this.twoTree = false;
    },
    //组装知识点的树形结构
    getToList() {
      getQuestionPoint({
        level: this.getLevelId,
        subjectId: this.getSubjectId,
        chapterIds: this.submitData.chapterIds.join("#"),
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        this.knowledgeData = [];
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeData.push(obj);
          });
        // if (type) {
        //   this.setNewQuestionList();
        //   // this.twoTree = true;
        //   // this.chapterIdsType = true;
        //   // this.setNewQuestionList()
        // }
        // console.log(this.loreArr);
      });
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
    delTypeList(val) {
      // console.log(val);
      this.$confirm("确认删除当前行数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.typeList.map((item, index) => {
            if (item.questionId == val.questionId) {
              this.typeList.splice(index, 1);
            }
          });
          this.typeList.map((item) => {
            if (item.questionId > val.questionId) {
              item.questionId--;
            }
          });
          this.questionTypeData.map((index) => {
            if (index.questionTypeId == val.questionTypeId) {
              index.number--;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消“删除”操作",
          });
        });

      // console.log(this.typeList);
    },
    getChapter() {
      // 通过id获取章节
      // console.log(this.submitData);
      // console.log(this.submitData.bookIds);
      let arr = this.submitData.bookIds;
      if (arr.length == 0) {
        this.getbookchapterData = [];
        return;
      }
      let data = {
        bookIds: arr.join(","),
      };
      firstchapter(data).then((res) => {
        this.getbookchapterData = res.data.data;
      });
    },
    setBookData(value, type) {
      // 判断下是切换还是初始化 等于一就是初始化 不清空
      if (type != 1) {
        this.submitData.bookIds = [];
      }
      this.submitData.chapterIds = [];
      this.editionlistData.map((index) => {
        if (index.value == value) {
          this.bookListData = index.children;
        }
      });
    },
    getOptions() {
      //年级列表
      if (this.getLevelId == 2) {
        this.gradeOptions = this.gradeMiddleOptions;
      }
      if (this.getLevelId == 3) {
        this.gradeOptions = this.gradeHighOptions;
      }
      // 设置难度列表
      this.difficultyList = [{ label: "不限", value: 0 }].concat(
        this.difficultyList
      );
      this.difficultyList = JSON.parse(JSON.stringify(this.difficultyList));
      //获取省份
      getProvinceList().then((res) => {
        this.provinceData = res.data.data;
      });

      // /通过科目、学段查询教材列表列表
      getbooklistbylevelandsubject({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        // this.editionlistData = res.data.data;
        let array = [];
        res.data.data.forEach((index) => {
          if (array.length == 0 || array.indexOf(index.editionId) == -1) {
            array.push(index.editionId);
            this.editionlistData.push({
              label: index.editionName,
              value: index.editionId,
              children: [],
            });
          }
          if (array.indexOf[index.editionId] != -1) {
            this.editionlistData.forEach((item) => {
              if (item.value == index.editionId) {
                item.children.push({
                  label: index.name,
                  value: index.id,
                });
              }
            });
          }
        });
      });
      this.init();
      // 编辑的话就不请求了
      if (this.pageId) {
        return;
      }

      // 通过科目、学段查询题型
      getquestiontype({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      }).then((res) => {
        // this.questionTypeData = [];
        res.data.data.forEach((index) => {
          this.questionTypeData.push({
            questionTypeId: index.id,
            questionTypeName: index.name,
            number: 1,
          });
        });
        this.setTableData();
      });
    },

    setTableData() {
      this.typeList = [];
      let indexNumber = 0;
      this.questionTypeData.forEach((index) => {
        for (let i = 0; i < index.number; i++) {
          indexNumber++;
          this.typeList.push({
            questionId: indexNumber,
            questionTypeId: index.questionTypeId,
            questionTypeName: index.questionTypeName,
            knowledgeIds: [],
            knowledgeNames: [],
            difficulty: 0,
            score: 0,
          });
        }
      });
      // console.log(this.typeList);
    },
    setTypeList(value) {
      // console.log(value);
      // console.log(type);
      // 当前类型的所有列表
      let indexList = this.typeList.filter(
        (item) => item.questionTypeId == value.questionTypeId
      );
      // 如果列表中已经不存在当前类型的题了
      if (indexList.length == 0) {
        let questionId = "";
        this.typeList.map((item, index) => {
          if (item.questionTypeId < value.questionTypeId) {
            //已经是最后一题
            if (!this.typeList[index + 1]) {
              questionId = Number(item.questionId);
            }
            if (
              this.typeList[index + 1] &&
              this.typeList[index + 1].questionTypeId > value.questionTypeId
            ) {
              questionId = Number(item.questionId);
            }
          }
        });
        let data = {
          questionId: questionId + 1,
          questionTypeId: value.questionTypeId,
          questionTypeName: value.questionTypeName,
          knowledgeIds: [],
          knowledgeNames: [],
          difficulty: 0,
          score: 0,
        };
        this.typeList.map((index) => {
          if (index.questionId > questionId) {
            index.questionId++;
          }
        });
        this.typeList.splice(questionId, 0, data);
        return;
      }
      // 最后一项的id
      let maxId = Number(indexList[indexList.length - 1].questionId);
      // 如果前长度大于 number 就说明是减
      if (indexList.length > value.number) {
        this.typeList.splice(maxId - 1, 1);
        this.typeList.map((index) => {
          if (index.questionId > maxId) {
            index.questionId--;
          }
        });
      } else {
        let data = {
          questionId: maxId + 1,
          questionTypeId: value.questionTypeId,
          questionTypeName: value.questionTypeName,
          knowledgeIds: [],
          knowledgeNames: [],
          difficulty: 0,
          score: 0,
        };
        this.typeList.map((index) => {
          if (index.questionId > maxId) {
            index.questionId++;
          }
        });
        this.typeList.splice(maxId, 0, data);
      }
    },
    handleChange(value) {
      this.setTypeList(value);
    },
  },
};
</script>
<style lang="scss">
.breakdownPaper {
  .two-tree-box {
    display: flex;
    justify-content: space-between;
    > div {
      height: 400px;
      overflow-y: scroll;
      width: 50%;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #2474ed;
      }
    }
    .is-checked {
      .el-tree-node__content {
        > .el-tree-node__label {
          color: #2474ed;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.breakdownPaper {
  width: 1220px;
  margin: 65px auto 50px;
  padding: 20px;
  border: 1px solid #dddddd;
  position: relative;
  .my-link {
    position: absolute;
    right: 50px;
    top: 20px;
  }
  i {
    cursor: pointer;
  }
  .list-del {
    font-size: 20px;
  }
  .know-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .know-list {
      margin: 5px;
      color: #373737;
      background-color: #8ce4cc;
      padding: 0 10px;
      height: 32px;
      line-height: 30px;
      font-size: 12px;
      // color: #409eff;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #8ce4cc;
      // cursor: pointer;
      // margin-bottom: 8px;
    }
  }
  .el-input-number {
    width: 125px;
  }
  .range-box {
    padding-left: 20px;
    margin-bottom: 12px;
    .el-select {
      margin-left: 24px;
    }
  }
  .chapte-box {
    padding-left: 20px;
    margin-bottom: 12px;

    .chapte-list {
      display: flex;
      align-items: top;
      flex-wrap: wrap;
      margin-bottom: 12px;
    }
    .chapte-left-box {
      width: 15%;
    }
    .chapte-right-box {
      width: 85%;
    }
    // float: left;
  }
  .btm-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 24px 0;
    z-index: 10;
    background-color: #ffffff;
    border-top: 1px solid #dddddd;
    .btn-all {
      margin-right: 40px;
    }
  }
  .question-quantity {
    padding-left: 20px;

    .quantity-list {
      padding: 12px;
      border: 1px solid #e0e6f3;
      border-radius: 4px;
      width: 280px;
      margin-bottom: 12px;
      .el-input-number {
        margin-left: 8px;
      }
    }
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .el-button {
      color: $primary-color;
    }

    .el-input {
      width: 248px;
    }
    .title-left {
      width: 4px;
      height: 14px;
      background-color: $primary-color;
      margin-right: 16px;
    }
    .title-name {
      margin-right: 24px;
    }
    .title-msg {
      margin-right: 24px;
    }
  }
}
</style>
