import request from "@/core/services/axios";

export function upload(obj) {
  return request({
    url: "/resource/exampaperrelation/my/upload",
    method: "post",
    data: obj,
  });
}

export function exampapercondition(obj) {
  return request({
    url: "/resource/exampapercondition",
    method: "post",
    data: obj,
  });
}

export function searchTraining(id, obj) {
  return request({
    url: "/exam/examquestion/search/training/" + id,
    method: "post",
    data: obj,
  });
}

export function putExampapercondition(obj) {
  return request({
    url: "/resource/exampapercondition",
    method: "put",
    data: obj,
  });
}

export function getquestiontype(query) {
  return request({
    url: "/resource/examquestion/getquestiontype",
    method: "get",
    params: query,
  });
}

export function exampaperconditionPage(query) {
  return request({
    url: "/resource/exampapercondition/page",
    method: "get",
    params: query,
  });
}

export function firstchapter(query) {
  return request({
    url: "/resource/reschapter/firstchapter",
    method: "get",
    params: query,
  });
}

export function getreplacequestions(query) {
  return request({
    url: "/resource/exampapercondition/getreplacequestions",
    method: "get",
    params: query,
  });
}

export function continueconditionexampaper(query) {
  return request({
    url: "/resource/exampapercondition/continueconditionexampaper",
    method: "get",
    params: query,
  });
}

export function delExampapercondition(id) {
  return request({
    url: "/resource/exampapercondition/" + id,
    method: "delete",
    // params: query
  });
}

export function getExampaperplan(id) {
  return request({
    url: "/resource/exampaperplan/" + id,
    method: "get",
    // params: query
  });
}

export function getconditionexampaper(id) {
  return request({
    url: "/resource/exampapercondition/getconditionexampaper/" + id,
    method: "get",
    // params: query
  });
}

export function download(id) {
  return request({
    url: "/resource/exampapercondition/download/" + id,
    method: "get",
    // params: query
  });
}

export function getExampapercondition(id) {
  return request({
    url: "/resource/exampapercondition/" + id,
    method: "get",
    // params: query
  });
}
